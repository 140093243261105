<template lang="html">
    <div>
        <b-navbar type="light" class="nav-bg" fixed="top">
            <img src="../../assets/img/logo.png" class="rounded mx-auto d-block nav-img" >
        </b-navbar>
        <div class="container-fluid">
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
             <router-link class="btn btn-info text-center" to="/return-list" style="height: 37px;line-height: 1.5;width: 47%;margin: 2%;">
                            Distributor
            </router-link>
            <router-link class="btn btn-success text-center" to="/consumer-return-list" style="height: 37px;line-height: 1.5;width: 46%;margin: 1%;">
                            Consumer
            </router-link>
            <div style="margin-bottom: 100px;" v-show="showReturn">
                <b-row class="mt-2" v-for="item in returnItems" >
                    <b-col>
                        <b-card @click="returnInfo(item)">
                            <b-row class="justify-content-md-center">
                                <b-col>
                                    <h3>{{item.name}} ( {{item.location}} )</h3>
                                </b-col>
                            </b-row>
                            <hr class="mt-2 mb-2">
                            <b-row class="justify-content-md-center">
                                <b-col cols="3">
                                    <p>{{item.year}}</p>
                                    <p class="return-day">{{item.day}}</p>
                                    <p>{{item.month}}</p>
                                </b-col>
                                <b-col cols="5">
                                    <p>{{item.saller}}</p>
                                    <p>{{item.phone}}</p>
                                    <p>Qty : {{item.count}} </p>
                                </b-col>
                                <b-col cols="4">
                                    <p>-</p>
                                    <p class="text-capitalize">{{item.status}}</p>
                                    <p>-</p>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
            <div class="row status-info" v-show="showInfo">
                <div class="col-md-12 ">
                    <b-row class="mt-2 mb-2" v-for="(info, index) in returnItemInfo" >
                        <b-col>
                            <b-card>
                                <b-row class="justify-content-md-center">
                                    <b-col>
                                        <h3>
                                            {{info.item.name}} 
                                        </h3>
                                    </b-col>
                                </b-row>
                                <hr class="mt-2 mb-2">
                                <b-row class="justify-content-md-center">
                                    <b-col cols="6">
                                        <p class="mb-1">Qty</p>
                                        <p class="mb-1">Distri Damage</p>
                                        <p>Transport Damage</p>
                                    </b-col>
                                    <b-col cols="6">
                                        <p class="mb-1">
                                            <input type="number" v-model="info.quantity" disabled class="form-control" style="height: 26px;">
                                        </p>
                                        <p class="mb-1">
                                            <input type="number" v-model="info.distri_damage_quantity" disabled class="form-control" style="height: 26px;">
                                        </p>
                                        <p>
                                            <input type="number" v-model="info.damage_quantity" disabled class="form-control" style="height: 26px;">
                                        </p>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                    <textarea class="form-control" disabled style="background-color: #fff;">{{remark}}</textarea>
                    <a class="btn btn-success mt-2 text-white" style="width: 100%;"
                            @click="closeReturnInfo()">Close</a>
                </div>
            </div>
            <router-link class="float" to="consumer-return-create">
                <i class="fa fa-plus my-float" style="padding: 37%;"></i>
            </router-link>
        </div>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'item',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                showReturn: true,
                showInfo: false,
                returnItems: [],
                returnItemInfo: [],
                remark: ""
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                consumerReturnListAction: 'consumerReturnListAction'
            }),
            async orderList() {
                this.isLoading = true;
                await this.consumerReturnListAction().then(res => {
                    if (res.status == "success") {
                        this.returnItems = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            returnInfo(item) {
                this.showReturn = false
                this.showInfo = true
                this.returnItemInfo = item.info
                this.remark = item.distri_remark
            },
            closeReturnInfo() {
                this.showReturn = true
                this.showInfo = false
            },
        },
        async mounted() {
            this.orderList()
        }
    }
</script>

<style scoped lang="scss">
    td {
        padding: 5px;
    }
    .float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 70px;
        right: 3%;
        background-color: #0C9;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .float-filter {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 90px;
        right: 2%;
        background-color: #318be3;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .return-day {
        font-size: larger;
        font-weight: bold;
        font-style: italic;
    }

    .status-info {
        width: 100%;
        height: 100vh;
        background: hsl(210 11% 92% / 1);
        z-index: 9999;
        position: absolute;
    }
</style>